<template>
  <div
    class="detail-goods-info"
    da-expose-code="detailGoodsInfo"
  >
    <div 
      v-if="isUnPaiMoreAddressOrder" 
      class="goods-number__title"
    > 
      {{ unPaidMoreAddressTitle }}
    </div>
    <div
      v-if="showTitle"
      class="detail-good-flexbetween list-title new-list-title"
      :class="{ 'virtual-list-title': isXtraOrPrimeSplit || mallOrStoreGoodsFlag > 1 }"
    >
      <div class="info">
        <template v-if="statusText">
          <span
            class="order-status"
            :class="'order-status-' + statusClass[item[0].orderGoodsStauts]"
          ></span>
          <span
            :p="item[0].status"
            class="order-status-txt"
          >
            {{ statusText }}
          </span>
          <i
            class="iconfont icon-pro order-status-tips"
            @click="showOrderGoodsStatusEvt"
          ></i>
        </template>
        <template v-else-if="item[0].orderGoodsStauts == 1 && orderInfo.payment_method == 'cod'">
          <span
            class="order-status-green"
            :p="item[0].status"
          >
            {{ ordersStatus[6] }}
          </span>
        </template>
        <template v-else-if="item[0].orderGoodsStauts == 0 && orderInfo.payment_method == 'cod'">
          <span
            class="order-status-blue"
            :p="item[0].status"
          >
            {{ ordersStatus[13] }}
          </span>
        </template>
        <template v-else-if="item[0].orderGoodsStauts == 2">
          <span
            class="order-status"
            :class="'order-status-' + statusClass[item[0].orderGoodsStauts]"
          ></span>
          <span
            :p="item[0].status"
            class="order-status-txt"
          >
            <template v-if="item[0].isAllocating">
              {{ language.SHEIN_KEY_PWA_16965 }}
              <i
                class="iconfont icon-pro"
                @click="showAllocatingTip = true"
              ></i>
            </template>
            <template v-else>
              {{ orderGoodsStatus[item[0].orderGoodsStauts] }}
            </template>
          </span>
        </template>
        <template v-else-if="item[0].orderGoodsStauts == 4 && refundRecordStatus">
          <span class="order-status order-status-green"></span>
          <span
            :p="item[0].status"
            class="order-status-txt"
          >
            {{ language.SHEIN_KEY_PWA_15231 }}
            <i
              class="iconfont icon-pro"
              @click="showRefundTip = true"
            ></i>
          </span>
        </template>
        <template v-else>
          <span
            class="order-status"
            :class="'order-status-' + statusClass[item[0].orderGoodsStauts]"
          ></span>
          <span
            v-if="orderInfo.payment_type == 2 && item[0].orderGoodsStauts == 0"
            :p="item[0].status"
            class="order-status-txt"
          >
            {{ language.SHEIN_KEY_PWA_17214 }}
          </span>
          <span
            v-else
            :p="item[0].status"
            class="order-status-txt"
          >
            {{ orderGoodsStatus[item[0].orderGoodsStauts] }}
          </span>
        </template>
      </div>
      <span
        v-if="isShowReturnRefundRecordBtn"
        v-expose="{ id: '1-19-1-166' }"
        v-tap="{ id: '1-19-1-167' }"
        class="go-to-record"
        @click="jumpReturnRefundList(orderInfo)"
      >
        <span class="content text-ellipsis">{{ language.SHEIN_KEY_PWA_17013 }}</span>
        <sui_icon_more_right_16px_2
          size="16px"
          :is-rotate="locals.GB_cssRight"
        />
      </span>
      <!-- 催发货 -->
      <template
        v-if="
          !item[0].is_exchange &&
            item[0].orderGoodsStauts == 2 &&
            orderInfo.orderRemindToShipStatus != 4 &&
            showPartOperatorBtn
        "
      >
        <span
          class="j-urging-btn"
          @click="urgingGoods"
        >
          {{ language.SHEIN_KEY_PWA_15240 }} 
          <sui_icon_more_right_16px
            size="16px"
            :is-rotate="locals.GB_cssRight"
          />
        </span>
      </template>
    </div>
    <div
      v-for="(mallOrStore, index) in distinctionNewMallOrStoreList"
      :key="index"
      :class="['list-con-item', { 'list-con-item_last': index == distinctionNewMallOrStoreList.length - 1 }]"
    >
      <div
        v-if="mallOrStore.mall_name && !mallOrStore.isXtraOrPrimeFlag && !isSingleMall"
        class="mall-name-wapper"      
      >
        <!-- mall名称 -->
        <div
          class="mall-name"
          v-html="mallOrStore.mall_name"
        ></div>
        <sui_icon_doubt_12px_2 
          v-if="getMallNameNoticeText(mallOrStore) && isShowMallNameNotice"
          v-expose="{
            id: '1-19-1-142',
            data: {
              order_no: orderInfo.billno
            },
          }"
          :is-rotate="locals.GB_cssRight"
          class="mall-icon-margin"
          color="#959595"
          size="12px"
          @click.stop="openMallNameNotice(mallOrStore)"
        />
      </div>
      <div
        v-for="(store, storeIndex) in mallOrStore.storeList"
        :key="storeIndex"
        :class="`list-con-box list-con-box${storeIndex}`"
      >
        <div
          v-if="store.mall_name && !mallOrStore.isXtraOrPrimeFlag && !isSingleMall" 
          class="mall-name-wapper"
        >
          <!-- mall名称 -->
          <div
            v-if="store.mall_name && !mallOrStore.isXtraOrPrimeFlag"
            class="mall-name"
            v-html="store.mall_name"
          ></div>
          <sui_icon_doubt_12px_2 
            v-if="getMallNameNoticeText(mallOrStore) && isShowMallNameNotice"
            v-expose="{
              id: '1-19-1-142',
              data: {
                order_no: orderInfo.billno
              },
            }"
            class="mall-icon-margin"
            color="#959595"
            size="12px"  
            :is-rotate="locals.GB_cssRight"
            @click.stop="openMallNameNotice(mallOrStore)"
          />
        </div>
        <!-- store名称 -->
        <div
          v-if="store.store_name && !mallOrStore.isXtraOrPrimeFlag"
          class="store-name"
        >
          <div
            class="store-info"
            @click.stop="jumpToStoreIndex(store)"
          >
            <sui_icon_me_shop_15px
              v-if="orderResultAbtInfo.isNewStoreIcon"
              size="12px"
              class="logo-detail"
            />  
            <sui_icon_store_15px
              v-else 
              size="15px"
              class="logo-detail"
            />      
            <span
              class="store-text"
              :class="{ 'max-limit': showSellerInfo }"
              v-html="store.store_name"
            >
            </span>
            <sui_icon_more_right_12px
              v-if="orderResultAbtInfo.isOpenShopUrl && store.store_code != 1"
              :is-rotate="locals.GB_cssRight"
              class="store-icon"
              size="12px"
            />
          </div>
          <div
            v-if="showSellerInfo"
            class="store-info-more"
          >
            <span class="more-text">{{ language.SHEIN_KEY_PWA_24719 }}</span>
            <span
              v-expose="{ id: '1-19-1-104', data: { store_code: store.store_code } }"
              v-tap="{ id: '1-19-1-105', data: { store_code: store.store_code } }"
            >
              <sui_icon_doubt_12px_2
                class="store-icon"
                size="12px"
                :is-rotate="locals.GB_cssRight"
                @click="showSellerInfoModal(store)"
              />
            </span>
          </div>
        </div>
        <ul class="list-con">
          <product-item
            v-for="(product, pIndex) in sortGoodsList(store.newGoodsList)"
            :key="product.id"
            :isShowQuickShipTag="orderResultAbtInfo.isShowQuickShipTag"
            :index="pIndex + 1"
            :product="product"
            :chemicals-ids="chemicalsIds"
            :isXtraOrPrimeFlag="!mallOrStore.isXtraOrPrimeFlag"
            @cancelGoodsEvt="cancelGoodsEvt"
            @showRefundPage="showRefundPage"
            @exchangeGoodsEvt="exchangeGoodsEvt"
            @handleShowSuccessPanel="handleShowSuccessPanel"
            @show-warning="showP65Warning"
          />
          <transportTime
            v-if="
              [2, 3].includes(mallOrStoreGoodsFlag) &&
                showTransportTimeCond &&
                !mallOrStore.isXtraOrPrimeFlag
            "
            :type="mallOrStoreGoodsFlag"
            :language="language"
            :mallOrStoreObj="store"
            :orderInfo="orderInfo"
          />
        </ul>
      </div>
      <transportTime
        v-if="
          [4].includes(mallOrStoreGoodsFlag) &&
            showTransportTimeCond &&
            !mallOrStore.isXtraOrPrimeFlag
        "
        :type="mallOrStoreGoodsFlag"
        :language="language"
        :mallOrStoreObj="mallOrStore"
        :orderInfo="orderInfo"
      />
    </div>

    <ClientOnly>
      <SLazyMount>
        <s-dialog
          v-model:visible="showAllocatingTip"
          :show-close="false"
          append-to-body
          @close="showAllocatingTip = false"
        >
          <template #title>
            <div class="split-package-tip">
              {{ language.SHEIN_KEY_PWA_16966 }}
            </div>
          </template>
          <template #footer>
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="showAllocatingTip = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </SLazyMount>

      <SLazyMount>
        <s-dialog
          v-model:visible="cancelExchangeTip"
          :show-close="false"
          append-to-body
          @close="cancelExchangeTip = false"
        >
          <template #title>
            <div class="split-package-tip">
              {{ language.SHEIN_KEY_PWA_17517 }}
            </div>
          </template>
          <template #footer>
            <s-button-group
              hor
              :width="'100%'"
              :num="2"
            >
              <s-button-group-item @click="cancelExchangeEvt">
                {{ language.SHEIN_KEY_PWA_15252 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary']"
                @click="cancelExchangeTip = false"
              >
                {{ language.SHEIN_KEY_PWA_15253 }}
              </s-button-group-item>
            </s-button-group>
          </template>
        </s-dialog>
      </SLazyMount>

      <SLazyMount>
        <s-dialog
          v-model:visible="showRefundTip"
          :show-close="false"
          append-to-body
          @close="showRefundTip = false"
        >
          <template #title>
            <div class="split-package-tip">
              {{ !locals.IS_RW ? language.SHEIN_KEY_PWA_17439 : language.SHEIN_KEY_PWA_17440 }}
            </div>
          </template>
          <template #footer>
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="showRefundTip = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </SLazyMount>

      <!-- 加州警告弹窗 -->
      <SLazyMount>
        <s-dialog
          v-model:visible="p65TipsShow"
          :show-close="false"
          :append-to-body="true"
        >
          <template #title>
            <p class="p65-title">
              {{ language.SHEIN_KEY_PWA_20813 }}
            </p>
          </template>
          <div class="p65-tip">
            <span
              :class="{ 'rw-tips': locals.IS_RW }"
              v-html="p65TipsContent"
            ></span>
          </div>
          <template #footer>
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="closeDialogEvt"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </SLazyMount>

      <SLazyMount>
        <s-dialog
          v-model:visible="showOrderGoodsStatusDialog"
          :show-close="false"
          append-to-body
        >
          <template #title>
            <div class="split-package-tip">
              {{ showOrderGoodsStatusText }}
            </div>
          </template>
          <template #footer>
            <s-button
              :width="'100%'"
              :type="['primary', 'H80PX']"
              @click="showOrderGoodsStatusDialog = false"
            >
              {{ language.SHEIN_KEY_PWA_15146 }}
            </s-button>
          </template>
        </s-dialog>
      </SLazyMount>

      <SLazyMount>
        <SellerInfoDialog
          :visible="showSellerModal"
          :language="language"
          :seller-info="sellerInfo"
          :business-model="businessModel"
          :store-code="storeCode"
          :order-info="orderInfo"
          :store-name="storeName"
          @close="showSellerModal = false"
        />
      </SLazyMount>

      <CancelGoodsItem
        v-if="isOrderDetail"
        ref="cancelItemRef"
        :language="language"
        :order-info="orderInfo"
        :cancel-reason="9"
        :abt-info="orderResultAbtInfo"
      />
      <SLazyMount>
        <cccMallNameNoticeDialog 
          :language="language"
          :visible="isShowMallNotice"
          :content="cccMallNameNotice"
          @closeMallNmaeDialog="isShowMallNotice = false"
        />
      </SLazyMount>
    </ClientOnly>
  </div>
</template>

<script>
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import schttp from 'public/src/services/schttp'
import orderLogic from 'public/src/pages/common/orderLogic'
import { daEventCenter } from 'public/src/services/eventCenter'
import { BLIND_BOX_IMG } from 'public/src/pages/user/child_pages/orders/common/constants.js'
import { template, transformImg, stringifyQueryString } from '@shein/common-function'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { mapState, mapGetters, mapMutations } from 'vuex'
import transportTime from './components/transportTime.vue'
import productItem from './product_item.vue'
import CancelGoodsItem from './components/CancelGoodsItem.vue'
import cccMallNameNoticeDialog from 'public/src/pages/user/child_pages/orders/common/cccMallNameNoticeDialog.vue'
import { sui_icon_doubt_12px_2, sui_icon_me_shop_15px, sui_icon_more_right_16px, sui_icon_more_right_12px, sui_icon_more_right_16px_2, sui_icon_store_15px } from '@shein-aidc/icon-vue3'
import { cloneDeep } from 'lodash'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'

daEventCenter.addSubscriber({ modulecode: '1-19-1' })

let color = {
  0: 'blue',
  1: 'red',
  2: 'green',
  3: 'grey'
}
export default defineComponent({
  name: 'DetailGoodsInfo',
  directives: { tap, expose },
  components: {
    ClientOnly,
    SLazyMount,
    productItem,
    transportTime,
    CancelGoodsItem,
    cccMallNameNoticeDialog,
    sui_icon_more_right_12px,
    sui_icon_more_right_16px,
    sui_icon_doubt_12px_2,
    sui_icon_me_shop_15px,
    sui_icon_store_15px,
    sui_icon_more_right_16px_2,
    SellerInfoDialog: defineAsyncComponent(() => import(/* webpackChunkName: "OrderDetailGoods_Comp" */'./components/SellerInfoDialog.vue')),
    SButton,
    SDialog,
    SButtonGroupItem,
    SButtonGroup,
  },
  emits: [
    'handleShowSuccessPanel',
    'handle-track',
    'urging-goods',
  ],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    item: Array,
    // eslint-disable-next-line vue/require-default-prop
    mallOrStoreGoodsFlag: Number,
    // eslint-disable-next-line vue/require-default-prop
    jumpReturnRefundList: Function,
    tabSelected: [Number, String],
    isNewStoreIcon: Boolean,
    methodTabSelected: {
      type: Number,
      default: 0
    },
    isUnPaiMoreAddressOrder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusClass: {
        0: color[0],
        1: color[0],
        2: color[0],
        3: color[0],
        4: color[0],
        5: color[2],
        6: color[3],
        7: color[3],
        8: color[3],
        9: color[3],
        10: color[3],
        11: color[2],
        12: color[3],
        13: color[3],
        14: color[0],
        15: color[0],
        16: color[1],
        17: color[2],
        18: color[0],
        19: color[0]
      },
      cccMallNameNotice: '',
      showAllocatingTip: false,
      cancelExchangeTip: false,
      currentProduct: {},
      showRefundTip: false,
      p65TipsShow: false,
      p65TipsContent: '',
      showOrderGoodsStatusDialog: false,
      showOrderGoodsStatusText: '',
      showSellerModal: false,
      businessModel: 0,
      storeCode: '',
      storeName: '',
      isShowMallNotice: false,
      isReportMallNameDa: false,
    }
  },
  computed: {
    ...mapState('orderDetail', [
      'language', 'orderType', 'showPartOperatorBtn', 'orderResultAbtInfo', 'isSSR', 'locals',
      'orderInfo', 'orderStatusTextList'
    ]),
    ...mapGetters('orderDetail', ['orderGoodsStatus', 'ordersStatus']),
    // 是否在标题栏显示退款退货记录按钮
    isShowReturnRefundRecordBtn() {
      if (!this.orderResultAbtInfo?.useNewPackage) return false
      // Processing状态才显示退款按钮
      const isSelectedProcessing = (this.tabSelected == this.orderInfo?.processingTabIndex) && ([1, 3].includes(this.orderInfo._isShppedType))
      return this.orderInfo?.showReturnRefundRecord && isSelectedProcessing
    },
    // 是否显示状态栏
    showTitle() {
      if (!this.orderResultAbtInfo?.useNewPackage) return true
      if (this.orderInfo?.processingTabIndex == this.tabSelected) return true
      if (this.isShowReturnRefundRecordBtn) return true
      // 如果有分包却没有物流轨迹数据，降级直接显示状态栏
      if (this.orderInfo?._isShppedType == 1 && !this.orderInfo?.trackList?.length) {
        return true
      }
      return false
    },
    chemicalsIds() {
      let { p65_flag = [], orderStatus } = this.orderInfo || {}
      let skuArr = []
      if (orderStatus == 12) {
        const p65FlagList = p65_flag?.filter(item => item.flag == 1)
        // 商品sku集合
        skuArr = p65FlagList?.map(item => item.goods_sn)
      }
      return skuArr || []
    },
    isOrderDetail() {
      return this.$route.name === 'UserOrdersDetail'
    },
    refundRecordStatus() {
      const status = this.item[0] || {}
      const refund_record_status_list = status.refund_record_status_list || []
      const isExistRefundRecordStatus = refund_record_status_list.filter(item => item.status == '8')
      return isExistRefundRecordStatus.length
    },
    isSingleMall() {
      return this.orderInfo?.is_multi_mall != 1
    },
    // 会否是未支付订单
    isUnPaidOrder() {
      let { orderStatus } = this.orderInfo || {}
      let unPaidOrderStatus = [0, 12, 13]
      return unPaidOrderStatus.includes(+orderStatus)
    },
    showTransportTimeCond() {
      let type = this.mallOrStoreGoodsFlag
      if (!this.isUnPaidOrder) return false
      if (type == 1) return false
      if (type == 2 || type == 3) {
        let mallOrStoreArr = this.mallOrStoreList?.reduce(
          (totalArr, curArr) => (totalArr = [...totalArr, ...curArr.storeList]),
          []
        )
        return mallOrStoreArr?.length > 1
      }
      if (type == 4) {
        if (this.mallOrStoreList.length > 1) return true
        if (this.mallOrStoreList.length == 1) {
          let mallOrStoreArr = this.mallOrStoreList?.reduce(
            (totalArr, curArr) => (totalArr = [...totalArr, ...curArr.storeList]),
            []
          )
          return mallOrStoreArr?.length > 1
        }
      }
      return false
    },
    mallOrStoreList() {
      let flag = this.mallOrStoreGoodsFlag
      if ([1].includes(flag))
        return [{ storeList: [{ newGoodsList: orderLogic.sortOrderList(this.item) }] }]
      if ([2, 3].includes(flag))
        return [{ storeList: this.getStoreList(flag == 2 ? 'store_code' : 'mall_code') }]
      return this.groupMallOrStoreByCode()
    },
    orderGoodsStatusText() {
      let { pack_detail_status = '' } = this.item?.[0] || {}
      if (!orderLogic.orderListGetCccTextCond(this.orderInfo, this.orderResultAbtInfo?.isCanRefundAbt)) {
        return ''
      }
      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.orderInfo,
        order_or_package_status: pack_detail_status
      })
      return matchObj?.statusText || ''
    },
    statusText() {
      const BBCText = orderLogic.getBBCSpecailText({
        orderGoodsList: this.orderInfo?.orderGoodsList || [],
        shipping_country_id: this.orderInfo?.shipping_country_id,
        language: this.language
      })

      return this.orderGoodsStatusText || BBCText?.orderStatusText || ''
    },
    // 判断是否满足B2B2C模式订单
    overB2B2CCondition() {
      let cond = orderLogic.orderDetailB2B2CCond(this.orderInfo, this.orderResultAbtInfo?.isCanRefundAbt)
      return !!cond
    },
    rootGoodsList () {
      return this.item?.flat(Infinity) || []
    },
    initGoodsList() {
      // 未支付包含付费会员虚拟商品 单独一列展示
      const initGoodsList = this.rootGoodsList
      if (this.isXtraOrPrimeSplit) {
        return initGoodsList.filter(item => !(item.is_prime_goods || item.is_xtra_goods))
      }
      return initGoodsList
    },
    isXtraOrPrimeGoods() {
      return this.rootGoodsList.filter(item => item.is_prime_goods || item.is_xtra_goods)
    },
    isXtraOrPrimeSplit() {
      return this.rootGoodsList.length > 1 && this.isXtraOrPrimeGoods?.length
    },
    newMallOrStoreList() {
      let flag = this.mallOrStoreGoodsFlag

      if (flag == 1) return this.mallOrStoreList
      if (this.isXtraOrPrimeSplit && flag != 1) {
        let isXtraOrPrimeGoods = [
          ...(this.isXtraOrPrimeGoods?.filter(item => item.is_xtra_goods) || []),
          ...(this.isXtraOrPrimeGoods?.filter(item => item.is_prime_goods) || [])
        ]
        return [
          {
            storeList: [{ newGoodsList: isXtraOrPrimeGoods, isXtraOrPrimeFlag: true }],
            isXtraOrPrimeFlag: true
          }
        ].concat(this.mallOrStoreList)
      }
      return this.mallOrStoreList
    },
    // * 根据条件进一步的区分商品列表
    distinctionNewMallOrStoreList() {
      if(this.isUnPaiMoreAddressOrder){
        const addressItem = this.orderInfo?.address_list[this.methodTabSelected]
        const billnoList = addressItem?.billno_list || []
        let list = cloneDeep(this.newMallOrStoreList)
        if(this.methodTabSelected != 0) {
          return this.handleGoodList( { list, billnoList, needXtraOrPrime: false } )
        }else{
          return this.handleGoodList({ list, billnoList, needXtraOrPrime: true })
        }
      }
      return this.newMallOrStoreList
    },
    // * 未支付多地址商品数量标题
    unPaidMoreAddressTitle(){
      let goodsTotalNumber = 0
      this.distinctionNewMallOrStoreList.forEach(item => {
        item?.storeList?.forEach(store => {
          goodsTotalNumber += (store?.newGoodsList?.reduce((total, goods) => {
            return total + Number(goods?.quantity || 0)
          }, 0) || 0)
        })
      })
      const { shipping_method = '' } = this.orderInfo?.address_list[this.methodTabSelected] || {}  
      if (goodsTotalNumber > 1) {
        return template(shipping_method, goodsTotalNumber?.toString(), this.language.SHEIN_KEY_PWA_33599)
      } else {
        return template(shipping_method, this.language.SHEIN_KEY_PWA_33598)
      }
    },
    sellerInfo() {
      return this.orderInfo?.storeSellerInfo?.storeSellerInfo || {}
    },
    showSellerInfo() {
      let { orderStatus } = this.orderInfo
      let { showSellerInfo } = this.orderResultAbtInfo || {}
      return [0, 12, 13, 16].includes(+orderStatus) && showSellerInfo
    },
    orderGoodsList00() {
      // 直达商品列表
      return this?.orderInfo?.orderGoodsList?.[0]?.[0] ?? []
    },
    isShowMallNameNotice(){
      const handle = this.orderInfo?._allOrderGoodsList.flat(Infinity)
      return !handle?.every(i=>[5, 7, 16, 57, 20, 74, 75].includes(Number(i.status)))
    }
  },
  mounted() {
    this.scrollToProduct()
  },
  methods: {
    ...mapMutations('orderDetail', ['assignState']),
    transformImg,
    template,
    handleShowSuccessPanel(data) {
      this.$emit('handleShowSuccessPanel', data)
    },
    // 将福袋商品排在最前
    sortGoodsList(list = []) {
      // 是否有盲盒商品
      const blindBoxFlag = list.some(i => Boolean(i.product.blind_box_flag))
      if (blindBoxFlag) {
        const newGoodsList = cloneDeep(list)
        newGoodsList.forEach(item => {
          if (item.product.blind_box_flag) {
            item.product.goods_img = BLIND_BOX_IMG
          }
        })
        // 将blind_box_flag为1的排在前面
        newGoodsList.sort((a, b) => {
          if (a.product.blind_box_flag === b.product.blind_box_flag) {
            return 0
          }
          return a.product.blind_box_flag ? -1 : 1 
        })
        return newGoodsList
      }
      return list
    },
    // * 操作商品列表
    handleGoodList( { list = [], needXtraOrPrime = false, billnoList = [] }){
      list = list.filter( item => {
        if(item?.isXtraOrPrimeFlag){
          return needXtraOrPrime
        }
        item.storeList = item.storeList.filter( store => {
          store.newGoodsList = store?.newGoodsList?.filter( goods => billnoList.includes(goods.billno)) || []
          if(!store.newGoodsList.length){
            return false
          } else {
            return true
          }  
        })
        if(!item.storeList.length){
          return false
        } else {
          return true
        }  
      })
      return list
    },
    getMallNameNoticeText(mallData) {
      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.orderInfo,
        order_or_package_status: mallData.storeList[0]?.newGoodsList[0]?.pack_detail_status
      })
      return matchObj?.mallTraceText || ''
    },
    openMallNameNotice(mallData){
      if(!this.isReportMallNameDa) {
        daEventCenter.triggerNotice({
          daId: '1-19-1-143',
          extraData: {
            order_no: this.orderInfo.billno,
          }
        })
      }
      this.isReportMallNameDa = true
      this.cccMallNameNotice = this.getMallNameNoticeText(mallData)
      this.isShowMallNotice = true
    }, 
    showOrderGoodsStatusEvt() {
      let { pack_detail_status } = this.item?.[0] || {}
      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.orderInfo,
        order_or_package_status: pack_detail_status
      })
      const BBCText = orderLogic.getBBCSpecailText({
        orderGoodsList: this.orderInfo?.orderGoodsList || [],
        shipping_country_id: this.orderInfo?.shipping_country_id,
        language: this.language
      })
      this.showOrderGoodsStatusText = matchObj?.virtualTraceText || BBCText?.tipsText || ''
      this.showOrderGoodsStatusDialog = true
    },
    async getStoreSelects(params) {
      let res = await schttp({
        url: `/api/store/matchStoreJumpUrl/get?storeCode=${params}`
      })
      return res?.links || {}
    },
    async jumpToStoreIndex({ store_code, newGoodsList = [], display_store_code = '' }) {
      let storeCode = display_store_code || store_code
      if (!this.orderResultAbtInfo.isOpenShopUrl || storeCode == 1) return
      daEventCenter.triggerNotice({
        daId: '1-19-1-73',
        extraData: {
          shop_code: storeCode
        }
      })
      const goods_ids = []
      const cate_ids = []
      newGoodsList?.forEach(item => {
        // 数据提取
        if (item?.goods_id) {
          goods_ids.push(item.goods_id)
        }
        if (item?.product?.cat_id) {
          cate_ids.push(item.product.cat_id)
        }
      })
      let href_tail = '&rule_poskey=AfBuyShopItemList&refer_scene=order&refer_trigger_point=order' //web默认值
      if (goods_ids.length > 0) {
        // 数组去重，goods_ids字符串拼接
        const str = [...new Set(goods_ids)].join(',')
        href_tail += `&goods_ids=${str}&main_goods_id=${str}`
      }
      if (cate_ids.length > 0) {
        // 数组去重，cate_ids字符串拼接
        const str = [...new Set(cate_ids)].join(',')
        href_tail += `&cate_ids=${str}&main_cate_id=${str}`
      }
      const storeInfo = await this.getStoreSelects(storeCode)
      if (storeInfo?.[storeCode]) {
        if (href_tail) {
          location.href = `${this.locals.langPath}${storeInfo?.[storeCode]}${href_tail}`
        } else {
          location.href = `${this.locals.langPath}${storeInfo?.[storeCode]}`
        }
      }
    },
    cancelGoodsEvt(product) {
      this.$refs.cancelItemRef.cancelGoodsEvt(product)
    },
    showRefundPage(product) {
      this.$refs.cancelItemRef.showRefundPage(product)
    },
    async cancelExchangeEvt() {
      const billno = this.orderInfo.billno
      const id = this.currentProduct.id
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/getUnionRevokeRefund/query',
        data: {
          list: JSON.stringify([
            {
              billno,
              order_goods_ids: [id],
              refund_path: 1,
              is_current: 1,
              cancel_reason: 5,
              reason: 5
            }
          ])
        },
        headers: { blackbox: window._fmOpt?.__blackbox || '' }
      })
      this.cancelExchangeTip = false
      let hasOwnOrderGoods = res?.info?.success_list?.filter(item => item.billno == billno)
      if (res.code == '0' && hasOwnOrderGoods?.length) {
        // 取消订单需要触发homeCccxCouponUpdate事件，首页监听这个事件去更新劵包信息
        appEventCenter.emit('homeCccxCouponUpdate')
        SToast(this.language.SHEIN_KEY_PWA_21356)
      } else if (res.code == 666666 || res.code == 777777) {
        SToast(this.language.SHEIN_KEY_PWA_17115)
      } else {
        let hasOwnOrderMsg = res?.info?.fail_list?.find(item => item.billno == billno) || {}
        SToast(hasOwnOrderMsg?.tips || this.language.SHEIN_KEY_PWA_21354)
      }
      setTimeout(() => {
        location.reload()
      }, 1000)
    },
    async exchangeGoodsEvt(items = {}) {
      let res = await this.getMallAndStoreInfoByBillno(items)
      items = {
        ...items,
        store_code: res?.store_code || items?.store_code
      }
      const { billno = '' } = this.orderInfo || {}
      const {
        goods_id = '',
        product = {},
        avgPrice = {},
        id = '',
        store_code = '',
        mall_code = '',
        special_price_vo = {},
        retail_price_vo = {},
        sku_code = '',
        is_exchange
      } = items || {}
 
      const { cat_id = '', salePrice = {} } = product || {}
      const { usdAmount: avg_usdAmount = '' } = avgPrice || {}
      const { usdAmount: sale_usdAmount = '' } = salePrice || {}
      // 价格参数传入美分
      let goodsPrice
      if (orderLogic.isFreeGiftItem(items)) {
        const freeGiftPrice = special_price_vo?.usdAmount || retail_price_vo?.usdAmount || 0
        goodsPrice = this.numberThusEvt(Number(freeGiftPrice))
      } else {
        goodsPrice = this.numberThusEvt(Number(sale_usdAmount || avg_usdAmount || 0))
      }

      const s_code = store_code || ''
      const m_code = mall_code ? (mall_code != '0' ? mall_code : '') : ''
      const optionParams = {
        billno,
        goods_ids: goods_id,
        cate_ids: cat_id,
        goods_price: goodsPrice,
        id,
        s_code,
        m_code,
        sku_code,
        sale_price: JSON.stringify(salePrice),
        isSoldOut: !!is_exchange
      }

      let resultUrl = `${this.locals.langPath}/user/orders/selfExchange?${stringifyQueryString({
        queryObj: { ...optionParams },
      })}`

      location.href = resultUrl
    },
    async getMallAndStoreInfoByBillno({ id } = {}) {
      let res = await schttp({
        url: `/api/orders/base/getOrderBaseInfo/get`,
        params: { billno: this.orderInfo.billno || '' }
      })
      let infoList = res?.info?.info || []
      let curGoodsStoreObj = {}
      infoList?.forEach(item => {
        let { order_goods_res_list = [] } = item || {}
        order_goods_res_list?.forEach(goodsObj => {
          if (goodsObj?.id_ots == id) {
            curGoodsStoreObj = goodsObj || {}
          }
        })
      })
      let { store_code = '' } = curGoodsStoreObj || {}
      return { store_code: `${store_code}` }
    },
    numberThusEvt(value) {
      if (!value) return value
      value = (value * 100).toFixed(2)
      return Number(value)
    },
    handleTrack(id) {
      this.$emit('handle-track', id)
      sa('send', {
        activity_name: 'click_track'
      })
    },
    urgingGoods (e) {
      this.$emit('urging-goods', e)
    },
    closeDialogEvt() {
      this.p65TipsShow = false
    },
    showP65Warning({ text }) {
      daEventCenter.triggerNotice({
        daId: '1-19-1-75'
      })
      this.p65TipsContent = text
      this.p65TipsShow = true
      sa('send', { activity_name: 'click_p65warning' })
    },
    getCodeByKey(code) {
      let { mall_list = [] } = this.orderInfo || {}
      let orderGoodsList = this.initGoodsList?.flat(Infinity)
      let mapList = {}
      if (code == 'store_code') {
        orderGoodsList?.forEach(goodsObj => {
          if (!mapList[goodsObj[code]]) {
            mapList[goodsObj[code]] = {
              business_model: goodsObj.business_model,
              store_logo: goodsObj.store_logo,
              store_name: goodsObj?.display_store_name || goodsObj.store_name,
              store_code: goodsObj.store_code,
              display_store_code: goodsObj?.display_store_code,
            }
          }
        })
      } else {
        mall_list?.forEach(mall => {
          if (!mapList[mall[code]]) {
            mapList[mall[code]] = {
              mall_code: mall.mall_code,
              mall_name: mall.mall_name
            }
          }
        })
      }
      return Object.values(mapList)
    },
    getStoreList(code) {
      let codeMap = this.getCodeByKey(code) || []
      let orderGoodsList = this.initGoodsList?.flat(Infinity)
      let codeList = []
      codeMap?.forEach(codeObj => {
        let tempNewGoodsList = JSON.parse(JSON.stringify(orderGoodsList))
        let filterGoodsList = tempNewGoodsList?.filter(goodsObj => {
          return goodsObj[code] == codeObj[code]
        })
        codeList.push({
          ...codeObj,
          newGoodsList: filterGoodsList
        })
      })
      return codeList
    },
    groupMallOrStoreByCode() {
      let { mall_list = [] } = this.orderInfo || {}
      let orderGoodsList = this.initGoodsList?.flat(Infinity)
      let mallGoodsInfo = {}
      mall_list?.forEach(mall => {
        if (!mallGoodsInfo[mall.mall_code]) {
          mallGoodsInfo[mall.mall_code] = {
            mall_code: mall.mall_code,
            mall_name: mall.mall_name,
            storeList: []
          }
        }
      })
      let mallValues = Object.values(mallGoodsInfo) || []
      mallValues.forEach(mallGoods => {
        orderGoodsList?.forEach(goods => {
          if (goods.mall_code == mallGoods.mall_code) {
            let isExistCodeList =
              mallGoods?.storeList?.filter(list => list.store_code == goods.store_code) || []
            !isExistCodeList?.length &&
              mallGoods.storeList.push({
                store_code: goods.store_code,
                store_name: goods?.display_store_name || goods.store_name,
                business_model: goods.business_model,
                store_logo: goods.store_logo,
                display_store_code: goods?.display_store_code,
                newGoodsList: []
              })
          }
          mallGoods?.storeList?.forEach(storeObj => {
            let tempNewGoodsList = JSON.parse(JSON.stringify(orderGoodsList))
            let filterGoodsList = tempNewGoodsList.filter(goodsObj => {
              return (
                goodsObj.store_code == storeObj.store_code &&
                goodsObj.mall_code == mallGoods.mall_code
              )
            })
            storeObj.newGoodsList = filterGoodsList
          })
          mallGoods.storeList = mallGoods?.storeList?.filter(item => !!item?.newGoodsList?.length)
        })
      })
      return mallValues
    },
    showSellerInfoModal(store = {}) {
      const {
        business_model,
        store_code,
        store_name,
        display_store_code
      } = store
      this.businessModel = business_model
      this.storeCode = display_store_code || store_code
      this.storeName = store_name
      this.showSellerModal = true
    },
    scrollToProduct() {
      const urlParams = new URLSearchParams(window.location.search)
      const soldoutArchor = urlParams.get('soldoutArchor')
      if (!soldoutArchor) {
        return
      }

      const targetElement = document.querySelector('.package-ctn')

      if (targetElement) {
        // 锚定到第一个商品
        window.scrollTo({
          top:
            targetElement.getBoundingClientRect().top -
            document.querySelector('.detail-container-z-header').clientHeight,
          behavior: 'smooth'
        })
      }
    }
  },
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.detail-goods-info {
  margin-bottom: 0.32rem;
  &:last-child {
    margin-bottom: 0;
  }
  .goods-number__title {
    color: @sui_color_gray_dark1;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 44 / 75rem
  }
}
.detail-good-flexbetween {
  .flexbox();
  .space-between();
  &.virtual-list-title {
    padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}
.list-con {
  padding: 0.3rem;
  .border-dpr(border-bottom,2px,#e5e5e5);
  background: #fff;
  > li {
    .flexbox();
    flex-flow: row wrap;
    padding: 24/75rem 0;
    &:last-child {
      padding: 24/75rem 0 0 0;
    }
    &:first-child {
      padding: 0;
    }
    > .list-href {
      .flexbox();
      text-decoration: none;
      position: relative;
    }
    .left-inner {
      width: 2rem;
      height: 2.64rem;
      .margin-r(0.2rem);
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .mask_ctn {
        position: absolute;
        top: 0;
        .left(0);
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
      .soldOut-box {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        .left(0);
        transform: translate(0, -50%);
        color: #fff !important; /* stylelint-disable-line declaration-no-important */
        i {
          .font-dpr(60px);
          line-height: normal;
          display: inline-block;
        }
        .text {
          line-height: normal;
          text-transform: capitalize;
          .font-dpr(24px);
        }
      }
    }
    .inner {
      width: 7rem;
      .operator_box {
        margin-top: 0.213rem;
        .exchange_tips {
          .font-dpr(24px);
          color: rgba(
            255,
            65,
            28,
            1
          ) !important; /* stylelint-disable-line declaration-no-important */
        }
        .exchange_btn {
          margin-top: 0.1rem;
          a {
            display: inline-block;
            height: 56/75rem;
            .margin-r(0.2rem);
            .font-dpr(24px);
            padding: 0.1rem 0.2rem;
            border: 1px solid rgba(229, 229, 229, 1);
            text-decoration: none;
            color: #222222 !important; /* stylelint-disable-line declaration-no-important */
            & when (@IS_RW) {
              height: 0.7rem;
              line-height: 0.7rem;
              border-radius: 0.35rem;
              padding: 0 0.2rem;
            }
          }
        }
      }
      > p {
        line-height: 1.5;
        .font-dpr(24px);
      }
      .size {
        margin-top: 0.213rem;
        color: #666;
        > em {
          display: block;
        }
      }
      .price {
        color: #222;
        > a {
          color: #222;
          display: block;
        }
      }
      .tip-spec-wrap {
        display: inline-block;
        position: relative;
        font-weight: 400;
        p {
          padding: 0.1rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          bottom: 0.6rem;
          .right(0);
          background: #fff;
          width: 6rem;
          z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
          .font-dpr(24px);
        }
        [class*='iconfont'] {
          color: #999999;
        }
        &.detail {
          p {
            position: absolute;
            top: 50%;
            bottom: auto;
            .right(0.64rem);
            transform: translateY(-50%);
            padding: 0.213rem 0.426rem;
            color: #666;
            .font-dpr(28px);
            width: 8.48rem;
            box-shadow: 0 2px 16px 4px rgba(0, 0, 0, 0.16);
            border-radius: 1px;
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              .right(-0.32rem);
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border: 0.16rem solid transparent;
              border-left: 0.16rem solid #fff;
            }
          }
        }
      }
      .struct {
        align-items: flex-start;
        .struct-goods-name {
          .font-dpr(24px);
          color: #666666;
          font-weight: 400;
          .margin-r(0.11rem);
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .struct-price {
          .txt-r();
          .font-dpr(24px);
          span {
            .font-dpr(24px);
            color: #222;
            font-weight: bold;
            &.struct-price__dis {
              color: @sui_color_discount;
              & when (@IS_RW) {
                color: @color_brand;
              }
            }
            &.struct-price-prime {
              color: #c96e3f;
            }
          }
          del {
            .font-dpr(20px);
            color: @sui_color_gray_light1;
            font-weight: 400;
          }
        }
      }
      .size-info {
        align-items: flex-start;
        margin-top: 10/75rem;
        .size-inf {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        &.flex-end {
          justify-content: flex-end;
        }
      }
      .goods-qs-tag {
        margin-top: 8/75rem;
        display: inline-block;
        color: #198055;
        .font-dpr(20px);
        background: #f5fcfb;
        padding: 3/75rem 8/75rem;
      }
    }
    .exchange-goods {
      .size {
        opacity: 0.3;
        color: #666666 !important; /* stylelint-disable-line declaration-no-important */
      }
      .price {
        opacity: 0.3;
        color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        > a {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
      .struct {
        .struct-goods-name {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
        .struct-price {
          span {
            opacity: 0.3;
            color: #666666 !important; /* stylelint-disable-line declaration-no-important */
          }
          del {
            opacity: 0.3;
            color: #666666 !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }
      .tip-spec-wrap {
        p {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }
}

.list-con-item {
  padding-bottom: .32rem;
  &.list-con-item_last{
    padding-bottom: 0;
  }
  .mall-icon-margin {
    margin-left: 8/75rem;
  }
  .mall-name-wapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 16/75rem 0;
  }
  .mall-name {
    text-transform: capitalize;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    .font-dpr(28px);
  }
  .store-name {
    display: flex;
    text-align: left;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 20/75rem 0;
    width: 100%;
    .store-info {
      display: flex;
      align-items: center;
      .store-logo {
        width: 30/75rem;
        height: 30/75rem;
        display: inline-block;
        .margin-r(8/75rem);
        border-radius: 50%;
      }
      .logo-detail {
        font-weight: initial;
        .font-dpr(30px);
        display: inline-block;
        .margin-r(8/75rem);
      }
      .store-text {
        display: inline-block;
        text-transform: capitalize;
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;
        .font-dpr(24px);
        &.max-limit {
          max-width: 400/75rem;
        }
      }
      .store-icon {
        .font-dpr(24px);
        .padding-l(8/75rem);
        color: #222;
        display: inline-block;
      }
    }
    .store-info-more {
      display: flex;
      color: #666;
      .more-text {
        font-size: 22/75rem;
        font-weight: normal;
        margin-right: 9/75rem;
      }
      .store-icon {
        cursor: pointer;
        // font-size: 24/75rem;
        color: #959595;
      }
    }
  }
  .list-con {
    padding: 0;
    border: none;
  }
  .show-aging-text {
    background: #f6f6f6;
    .font-dpr(24px);
    .txt-l();
    padding: 16/75rem;
    margin-top: 0.2rem;
    font-family: 'SF UI Display';
    font-style: normal;
    .title {
      .font-dpr(24px);
      color: #222222;
      font-weight: 400;
    }
    .text {
      .font-dpr(24px);
      color: #959595;
    }
  }
  .aging-text {
    margin-bottom: 13/75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.order-status-tips {
  .margin-l(0.1rem);
}
</style>
